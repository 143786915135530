import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
import { HeroLayout } from '@uchiru/gatsby-theme-doctocat';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = HeroLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Uchiru Components`}</h2>
    <p>{`Uchiru Components is a React implementation of Uchiru's `}<a parentName="p" {...{
        "href": "https://style.onlineschool.ru/"
      }}>{`Uchiru Design System`}</a>{` 🎉`}</p>
    <h2>{`Principles`}</h2>
    <ul>
      <li parentName="ul">{`Everything is a component.`}</li>
      <li parentName="ul">{`Aim for total style encapsulation; don't rely on inheritance to provide default styles.`}</li>
      <li parentName="ul">{`Build small building blocks with minimal props to keep complexity low.`}</li>
      <li parentName="ul">{`Keep system constrained by only including props needed per component.`}</li>
      <li parentName="ul">{`Favor wrapping or extending components for more complex operations.`}</li>
      <li parentName="ul">{`Maintain design system consistency with utilities as props (for spacing, color, font-size, line-height, widths, and radii).`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "/about/getting-started"
      }}>{`our getting started guide`}</a>{` for everything you need to know about installing and using Uchiru Components.`}</p>
    <h2>{`Local development`}</h2>
    <p>{`To run `}<inlineCode parentName="p">{`@uchiru/components`}</inlineCode>{` locally when adding or updating components:`}</p>
    <ol>
      <li parentName="ol">{`Clone this repo: `}<inlineCode parentName="li">{`git clone git@github.com:uchiru/components`}</inlineCode></li>
      <li parentName="ol">{`Install dependencies: `}<inlineCode parentName="li">{`yarn`}</inlineCode></li>
      <li parentName="ol">{`Run the dev app: `}<inlineCode parentName="li">{`yarn start`}</inlineCode></li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`👉 See `}<a parentName="p" {...{
          "href": "https://github.com/uchiru/components/blob/master/CONTRIBUTING.md"
        }}>{`the contributing docs`}</a>{` for more info on code style, testing, and coverage.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      